import { trezor, optimism, ledger, polygon, gridplus, ens, base, ipfs, arbitrum, aave } from '../svg';
const ribbonItems = [
    {
        svg: ledger,
        alt: 'Ledger',
        link: 'https://www.ledger.com/'
    },
    {
        svg: trezor,
        alt: 'Trezor',
        link: 'https://trezor.io/'
    },
    {
        svg: gridplus,
        alt: 'GridPlus',
        link: 'https://gridplus.io/'
    },
    // {
    //   image: '/images/ribbon/ribbon-1.png',
    //   alt: 'Aave',
    //   link: 'https://www.google.com'
    // },
    // {
    //   image: '/images/ribbon/ribbon-1.png',
    //   alt: 'Curve',
    //   link: 'https://www.google.com'
    // },
    // {
    //   image: '/images/ribbon/ribbon-1.png',
    //   alt: 'Uniswap',
    //   link: 'https://www.google.com'
    // },
    {
        svg: ipfs,
        alt: 'IPFS',
        link: 'https://ipfs.tech/'
    },
    {
        svg: ens,
        alt: 'ENS',
        link: 'https://ens.domains/'
    },
    // {
    //   image: '/images/ribbon/ribbon-1.png',
    //   alt: 'macOS',
    //   link: 'https://www.google.com'
    // },
    // {
    //   image: '/images/ribbon/ribbon-1.png',
    //   alt: 'Windows',
    //   link: 'https://www.google.com'
    // },
    // {
    //   image: '/images/ribbon/ribbon-1.png',
    //   alt: 'Linux',
    //   link: 'https://www.google.com'
    // },
    {
        svg: optimism,
        alt: 'Optimism',
        link: 'https://www.optimism.io/'
    },
    {
        svg: arbitrum,
        alt: 'Arbitrum',
        link: 'https://arbitrum.io/'
    },
    {
        svg: base,
        alt: 'Base',
        link: 'https://base.org/'
    },
    {
        svg: polygon,
        alt: 'Polygon',
        link: 'https://polygon.technology/polygon-zkevm'
    },
    {
        svg: aave,
        alt: 'Aave',
        link: 'https://aave.com/'
    }
];
const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
};
const splitArrayInHalf = (array) => {
    const middleIndex = Math.floor(array.length / 2);
    const firstHalf = array.slice(0, middleIndex);
    const secondHalf = array.slice(middleIndex);
    return [firstHalf, secondHalf];
};
const randomizeAndSplitArray = (array) => {
    const randomizedArray = shuffleArray(array);
    return splitArrayInHalf(randomizedArray);
};
export default () => {
    return randomizeAndSplitArray(ribbonItems);
};
