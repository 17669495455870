import React from 'react';
import styled from '../../styled';
import _Balancer from 'react-wrap-balancer';
let Balancer = styled.div ``;
if (typeof window !== 'undefined') {
    Balancer = _Balancer;
}
import { Cluster, ClusterRow, ClusterValue, ClusterColumn, ClusterAdapt } from '../Cluster';
import { GlitchGlyph } from '../Glitch';
import { microscope, machine, crystalBall, shieldCheck, mask, shieldKey, panel, pr, convo, usb, discord, ledger, trezor, gridplus, github, chrome, firefox, twitter } from '../svg';
const contentCutoff = '1000px';
const ContentImageWrap = styled(ClusterValue) `
  /* background: var(--ghostB); */
  --size: calc((var(--target-width) / 4) - 16px);
  text-align: center;
  color: var(--mint);
  /* background: ${(props) => (props.img ? `url(${props.img})` : 'var(--ghostC)')}; */
  /* background: url('https://frame.nyc3.cdn.digitaloceanspaces.com/home/assets/Screenshot%202023-04-26%20at%206.00.27%20PM.png'); */
  background-size: cover;
  background-position: center;
  background: transparent;
  box-shadow: none;
  border-bottom: 2px solid transparent;

  svg {
    height: 60px;
    width: 60px;
  }
`;
const ClusterAdapter = styled(Cluster) `
  overflow: hidden;
  ${ClusterRow} {
    ${ContentImageWrap} {
      width: var(--size);
      min-width: var(--size);
      max-width: var(--size);
      min-height: calc(var(--size) / 2);
    }
  }
  ${ClusterColumn} {
    ${ContentImageWrap} {
      --size: calc(var(--target-width) / 3);
      width: auto;
      max-width: 100%;
      min-width: 100%;
      height: var(--size);
      min-height: var(--size);
      max-height: var(--size);
    }
  }
`;
const ClusterOverlay = styled.div `
  position: absolute;
  inset: 0;
  background: ${({ reverse, color }) => {
    return `linear-gradient(${reverse ? '225deg' : '135deg'}, ${color} 0%, transparent 90%)`;
}};
  opacity: 0.03;
  z-index: 300000;
  pointer-events: none !important;
`;
export const ContentBreak = styled.div `
  height: 2px;
  border-radius: 1px;
  width: 300px;
  margin: 54px auto 80px auto;
  text-align: center;
  box-sizing: border-box;
  color: var(--mintOver);
  --bg-size: 300%;
  background: linear-gradient(90deg, var(--mint), var(--mint), #ffeae6, #edd3ff, var(--mint)) 0 0 /
    var(--bg-size) 100%;
  font-size: 18px;
  font-weight: 800;

  animation: move-bg 24s linear infinite;
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
`;
const ContentBox = styled(ClusterValue) `
  flex-direction: column;
  padding: 32px;
  text-align: center;
  box-sizing: border-box;
  @media (max-width: ${contentCutoff}) {
    padding: 32px;
  }
`;
const ClusterAdapterInsert = styled(ClusterAdapter) `
  pointer-events: auto;
  margin-top: 32px;

  svg {
    height: 22px;
    width: auto;
    margin: 16px;
  }
  /* div {
    margin: 0px 16px 0px 8px;
  } */
  ${ContentBox} {
    flex-direction: row;
    pointer-events: auto !important;
    padding: 2px !important;
    min-width: 110px;
  }
  @media (max-width: 720px) {
    & {
      width: 100%;
    }
  }
`;
const ContentTitle = styled.div `
  font-size: 26px;
  font-weight: 200;
  --bg-size: 200%;
  font-family: 'VCR';
  text-transform: uppercase;
  background: linear-gradient(
      90deg,
      var(--mintOver),
      var(--mintOver),
      var(--mint),
      var(--mintOver),
      var(--mintOver)
    )
    0 0 / var(--bg-size) 100%;
  /* background: ${({ reverse, color }) => color}; */
  color: transparent;
  -webkit-background-clip: text;
  width: 100%;
  background-clip: text;
  animation: move-bg 12s linear infinite;
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
`;
const ContentBody = styled(Balancer) `
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding-top: 32px;

  b {
    color: var(--outerspace);
  }
`;
// const ContentModule = ({
//   reverse,
//   color,
//   children
// }: {
//   reverse?: boolean
//   color?: string
//   children: JSX.Element[]
// }) => {
//   return (
//     <ClusterAdapter>
//       <ClusterAdapt reverse={reverse}>
//         <ContentImageWrap />
//         <ContentBox>
//           <ClusterOverlay color={color} />
//           {children}
//         </ContentBox>
//       </ClusterAdapt>
//     </ClusterAdapter>
//   )
// }
export const Omnichain = ({ reverse, color }) => {
    return (React.createElement(ClusterAdapter, null,
        React.createElement(ClusterAdapt, { reverse: reverse },
            React.createElement(ContentImageWrap, { transparent: true },
                React.createElement(GlitchGlyph, null, crystalBall)),
            React.createElement(ContentBox, null,
                React.createElement(ClusterOverlay, { reverse: reverse, color: color }),
                React.createElement(ContentTitle, { color: color }, "Omnichain Routing"),
                React.createElement(ContentBody, null,
                    React.createElement("b", null, "Eliminate the concept of \"being on a chain\" with omnichain routing!"),
                    " Operate across multiple chains simultaneously for a truly multichain experience \u2014 ",
                    React.createElement("b", null, "with support for all EVM chains!"))))));
};
export const Transactions = ({ reverse, color }) => {
    return (React.createElement(ClusterAdapter, null,
        React.createElement(ClusterAdapt, { reverse: reverse },
            React.createElement(ContentImageWrap, null,
                React.createElement(GlitchGlyph, null, microscope)),
            React.createElement(ContentBox, null,
                React.createElement(ClusterOverlay, { reverse: reverse, color: color || 'rgba(235, 125, 255, 1)' }),
                React.createElement(ContentTitle, { color: color }, "Decoded Transactions"),
                React.createElement(ContentBody, null,
                    React.createElement("b", null, "Decode transaction calldata locally"),
                    " using verified ABIs to reveal important details about your transactions. Enjoy easy-to-consume, contextually informed summaries for every transaction, enhancing transparency and security.")))));
};
export const Hardware = ({ reverse, color }) => {
    return (React.createElement(ClusterAdapter, null,
        React.createElement(ClusterAdapt, { reverse: reverse },
            React.createElement(ContentImageWrap, null,
                React.createElement(GlitchGlyph, null, usb)),
            React.createElement(ContentBox, null,
                React.createElement(ClusterOverlay, { reverse: reverse, color: color || 'rgba(235, 125, 255, 1)' }),
                React.createElement(ContentTitle, { color: color }, "S-Tier Hardware Support"),
                React.createElement(ContentBody, null,
                    React.createElement("b", null, "Having hardware wallet issues?"),
                    " Frame's",
                    ' ',
                    React.createElement("b", null, "OS-level integrations with Ledger, Trezor, and Gridplus"),
                    " devices provide a more reliable connection."),
                React.createElement(ClusterAdapterInsert, null,
                    React.createElement(ClusterAdapt, null,
                        React.createElement(ContentBox, { onClick: () => {
                                window.open('https://www.ledger.com/', '_blank');
                            } }, ledger),
                        React.createElement(ContentBox, { onClick: () => {
                                window.open('https://trezor.io/', '_blank');
                            } }, trezor),
                        React.createElement(ContentBox, { onClick: () => {
                                window.open('https://gridplus.io/', '_blank');
                            } }, gridplus)))))));
};
export const Extension = ({ reverse, color }) => {
    return (React.createElement(ClusterAdapter, null,
        React.createElement(ClusterAdapt, { reverse: reverse },
            React.createElement(ContentImageWrap, null,
                React.createElement(GlitchGlyph, null, panel)),
            React.createElement(ContentBox, null,
                React.createElement(ClusterOverlay, { reverse: reverse, color: color || 'rgba(235, 125, 255, 1)' }),
                React.createElement(ContentTitle, { color: color }, "Companion Extension"),
                React.createElement(ContentBody, null,
                    "Legacy dapp doesn't support Frame natively yet?",
                    ' ',
                    React.createElement("b", null, "Use our Companion Extension to inject a connection!"),
                    " You can even \"Appear as MetaMask\" to ensure compatibility with all dapps. Plus, enjoy ENS name verification and user inventory on Twitter!"),
                React.createElement(ClusterAdapterInsert, { icons: true },
                    React.createElement(ClusterAdapt, null,
                        React.createElement(ContentBox, { onClick: () => {
                                window.open('https://chrome.google.com/webstore/detail/frame/ldcoohedfbjoobcadoglnnmmfbdlmmhf', '_blank');
                            } }, chrome),
                        React.createElement(ContentBox, { onClick: () => {
                                window.open('https://addons.mozilla.org/en-US/firefox/addon/frame-extension', '_blank');
                            } }, firefox)))))));
};
export const SystemWide = ({ reverse, color }) => {
    return (React.createElement(ClusterAdapter, null,
        React.createElement(ClusterAdapt, { reverse: reverse },
            React.createElement(ContentImageWrap, null,
                React.createElement(GlitchGlyph, null, machine)),
            React.createElement(ContentBox, null,
                React.createElement(ClusterOverlay, { reverse: reverse, color: color || 'rgba(235, 125, 255, 1)' }),
                React.createElement(ContentTitle, { color: color }, "System-wide web3"),
                React.createElement(ContentBody, null,
                    "Frame creates a secure system-wide interface to your chains and accounts.",
                    ' ',
                    React.createElement("b", null, "Now any browser, command-line, or native application has the ability to access web3."),
                    " Break out of the browser and use Frame to interact with web3 from anywhere on your machine.")))));
};
export const Security = ({ reverse, color }) => {
    return (React.createElement(ClusterAdapter, null,
        React.createElement(ClusterAdapt, { reverse: reverse },
            React.createElement(ContentImageWrap, null,
                React.createElement(GlitchGlyph, null, shieldCheck)),
            React.createElement(ContentBox, null,
                React.createElement(ClusterOverlay, { reverse: reverse, color: color || 'rgba(235, 125, 255, 1)' }),
                React.createElement(ContentTitle, { color: color }, "Dedicated to Security"),
                React.createElement(ContentBody, null,
                    React.createElement("b", null, "Frame is dedicated to security"),
                    " and regularly undergoes audits conducted by industry-leading firms such as Cure53 and Doyensec, to ensure the highest level of protection for our users.")))));
};
export const OpenSource = ({ reverse, color }) => {
    return (React.createElement(ClusterAdapter, null,
        React.createElement(ClusterAdapt, { reverse: reverse },
            React.createElement(ContentImageWrap, null,
                React.createElement(GlitchGlyph, null, pr)),
            React.createElement(ContentBox, null,
                React.createElement(ClusterOverlay, { reverse: reverse, color: color || 'rgba(235, 125, 255, 1)' }),
                React.createElement(ContentTitle, { color: color }, "Open Source"),
                React.createElement(ContentBody, null,
                    React.createElement("b", null, "Frame is committed to open source,"),
                    " and our code freely available on GitHub. We welcome community input and contributions!"),
                React.createElement(ClusterAdapterInsert, { icons: true },
                    React.createElement(ClusterAdapt, null,
                        React.createElement(ContentBox, { onClick: () => {
                                window.open('https://github.com/floating/frame', '_blank');
                            } }, github)))))));
};
export const WatchOnly = ({ reverse, color }) => {
    return (React.createElement(ClusterAdapter, null,
        React.createElement(ClusterAdapt, { reverse: reverse },
            React.createElement(ContentImageWrap, null,
                React.createElement(GlitchGlyph, null, mask)),
            React.createElement(ContentBox, { reverse: reverse },
                React.createElement(ClusterOverlay, { reverse: reverse, color: color || 'rgba(235, 125, 255, 1)' }),
                React.createElement(ContentTitle, { color: color }, "Want to try Frame?"),
                React.createElement(ContentBody, null,
                    "You can try Frame ",
                    React.createElement("b", null, "without entering your private keys or connecting a device."),
                    " Use one of our",
                    ' ',
                    React.createElement("b", null, "\"Watch Only\""),
                    " accounts and take Frame for a test drive!")))));
};
export const Privacy = ({ reverse, color }) => {
    return (React.createElement(ClusterAdapter, null,
        React.createElement(ClusterAdapt, { reverse: reverse },
            React.createElement(ContentImageWrap, null,
                React.createElement(GlitchGlyph, null, shieldKey)),
            React.createElement(ContentBox, null,
                React.createElement(ClusterOverlay, { reverse: reverse, color: color || 'rgba(235, 125, 255, 1)' }),
                React.createElement(ContentTitle, { color: color }, "Privacy Focused"),
                React.createElement(ContentBody, null,
                    React.createElement("b", null, "Frame is built with the goal of keeping your data transparently under your control"),
                    " and we make it easy for you to point Frame at your choice of trusted infrastructure. Read more about our stance on privacy in our FAQ.")))));
};
export const Community = ({ reverse, color }) => {
    return (React.createElement(ClusterAdapter, null,
        React.createElement(ClusterAdapt, { reverse: reverse },
            React.createElement(ContentImageWrap, null,
                React.createElement(GlitchGlyph, null, convo)),
            React.createElement(ContentBox, null,
                React.createElement(ClusterOverlay, { reverse: reverse, color: color || 'rgba(235, 125, 255, 1)' }),
                React.createElement(ContentTitle, { color: color }, "Join The Community"),
                React.createElement(ContentBody, null,
                    React.createElement("b", null, "Join our community on Twitter and Discord,"),
                    " give us your feedback, and contribute to Frame's ongoing improvement."),
                React.createElement(ClusterAdapterInsert, { icons: true },
                    React.createElement(ClusterAdapt, null,
                        React.createElement(ContentBox, { onClick: () => {
                                window.open('https://discord.gg/rr4Yr3JkPq', '_blank');
                            } }, discord),
                        React.createElement(ContentBox, { onClick: () => {
                                window.open('https://twitter.com/0xFrame', '_blank');
                            } }, twitter)))))));
};
