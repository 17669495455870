import styled from '../../../styled';
const topCutOff = '800px';
export const TopBackdrop = styled.div `
  content: '';
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 150px;
  z-index: 3999;
  backdrop-filter: blur(16px);
  pointer-events: none;
  mask-image: linear-gradient(to bottom, black 32px, transparent);
  -webkit-mask-image: linear-gradient(to bottom, black 32px, transparent);

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    background: linear-gradient(90deg, var(--ghostY) 5%, transparent 10%, transparent 90%, var(--ghostY) 95%);
  }
  &:after {
    content: '';
    position: absolute;
    inset: 0px;
    z-index: 2;
  }
`;
export const Logo = styled.a `
  display: block;
  text-decoration: none;
  height: 20px;
  color: var(--mint);
  img {
    height: 20px;
    width: auto;
  }
  &:visited {
    color: var(--mint);
  }
`;
export const Menu = styled.div `
  display: flex;
`;
export const MenuItem = styled.a `
  display: block;
  padding: 2px 16px 0px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: var(--mintOver);
  text-decoration: none;

  svg {
    width: 20px;
    height: 20px;
  }

  &:visited {
    color: var(--mintOver);
  }
  &:hover,
  &:active {
    color: var(--mint);
  }
`;
export const MenuIcons = styled.div `
  display: flex;
`;
export const MenuIcon = styled.a `
  display: block;
  padding: 6px 10px 0px 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: var(--mint);
  text-decoration: none;
  svg {
    height: 16px;
  }
  &:hover {
    color: var(--mintOver);
  }
`;
export const Connection = styled.div `
  background: var(--ghostAZ);
  height: 32px;
  width: 110px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 2px 4px var(--ghostY);
  * {
    pointer-events: none;
  }
  &:hover {
    color: var(--good);
    background: var(--ghostA);
  }
`;
export const MenuLaunch = styled.div `
  display: flex;
  padding: 4px 8px 0px 8px;
  margin-right: -8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: var(--mint);
  svg {
    width: 24px;
    height: 24px;
  }
`;
export const Top = styled.div `
  position: fixed;
  top: 0px;
  right: 24px;
  bottom: 0px;
  left: 24px;
  height: 64px;
  padding: 22px 32px;
  z-index: 4000;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--ghostZ);
  border-left: 2px solid var(--ghostZ);
  border-right: 2px solid var(--ghostZ);
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  background: var(--ghostZ);
  box-shadow: 0 12px 8px -8px var(--ghostX);

  ${MenuLaunch} {
    display: none;
  }
  @media (max-width: ${topCutOff}) {
    ${Menu} {
      display: none;
    }
    ${MenuIcons} {
      display: none;
    }
    ${MenuLaunch} {
      display: block;
    }
    ${Connection} {
      display: none;
    }
  }
`;
export const TopExpanded = styled.div `
  position: fixed;
  inset: 0px;
  background: var(--ghostY);
  z-index: 3499;
  animation: fade-in 0.5s ease-in-out;
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
export const TopExpandedBody = styled.div `
  position: absolute;
  top: 64px;
  right: 32px;
  bottom: 0px;
  left: 32px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 32px 8px;
`;
export const TopExpandedItem = styled.a `
  display: block;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--ghostX);
  &:last-child {
    border-bottom: none;
  }
  &,
  &:visited,
  &:active {
    text-decoration: none;
    color: var(--mint);
  }
  &:hover {
    color: var(--mintOver);
  }
`;
